<template>
  <div>
    <CRow class="justify-content-center">
      <CCol sm="10" lg="12">
        <CForm @submit.prevent="getReportsData">
          <CRow class="align-items-center">
            <CCol sm="3" class="py-3">
              <CInput
                label="Start Date"
                placeholder="Enter your name"
                type="date"
                v-model="start_date"
              />
            </CCol>
            <CCol sm="3" class="py-3">
              <CInput
                label="Stop Date"
                placeholder="Enter your name"
                type="date"
                v-model="stop_date"
              />
            </CCol>
            <CCol sm="3" class="py-3"
              ><div class="form-group">
                <label for="terminal">Category</label>
                <select name="terminal" class="form-control" v-model="category">
                  <option value="" selected>Select Category</option>
                  <option value="bill-payments">
                    Bill Payments
                  </option>
                </select>
              </div></CCol
            >
            <CCol sm="3" class="py-3">
              <div class="form-group">
                <label for="terminal">Terminal</label>
                <select
                  name="terminal"
                  class="form-control"
                  v-model="customer_id"
                  :disabled="terminalLoading"
                >
                  <option v-if="terminalLoading" value="" selected disabled>
                    Getting Terminals...
                  </option>
                  <option value="" selected>Select Terminal</option>
                  <option
                    v-for="terminal in terminals"
                    :key="terminal.id"
                    :value="terminal.customer_id"
                  >
                    {{ terminal.name }}
                  </option>
                </select>
              </div>
            </CCol>
            <CCol sm="3" class="py-3">
              <CButton
                color="primary"
                class="mt-2"
                type="submit"
                :disabled="reportsLoading"
              >
                <span v-if="reportsLoading"
                  ><loader-icon size="1.5x" class="custom-class"></loader-icon
                  >Please Wait</span
                >
                <span v-else>Submit</span>
              </CButton>
            </CCol>
          </CRow>
        </CForm>
      </CCol>

      <CCol sm="10" lg="12">
        <vue-data-table
          ref="reports"
          :start_date="start_date"
          :stop_date="stop_date"
          :customer_id="customer_id"
          :category="category"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueDataTable from "@/components/VueDataTable";
import ApiService from "../services/api.service";
export default {
  name: "Report",
  components: {
    VueDataTable,
  },
  computed: {
    ...mapGetters(["reportsLoading"]),
  },
  data() {
    return {
      terminals: [],
      terminalLoading: false,
      customer_id: "",
      start_date: "",
      category: "",
      stop_date: "",
    };
  },
  methods: {
    async getTerminals() {
      this.terminalLoading = true;
      try {
        const response = await ApiService.get("partners/terminals");
        console.log(response);
        this.terminals = response.data.data;
        this.terminalLoading = false;
      } catch (error) {
        console.log(error.response);
        this.terminalLoading = false;
      }
    },
    getReportsData() {
      this.$refs.reports.getReports();
    },
  },
  mounted() {
    this.getTerminals();
  },
};
</script>

<style scoped></style>
