<template>
  <div class="table-responsive">
    <div v-if="reports.length > 0">
      <table class="table table-hover table-bordered" id="example">
        <thead>
          <tr>
            <th>Originator</th>
            <th>Title</th>
            <th>Amount</th>
            <th>Charge</th>
            <th>Total Amount</th>
            <th>Balance After</th>
            <th>Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(report, index) in reports" :key="index">
            <td>{{ report.Originator }}</td>
            <td>{{ report.title }}</td>
            <td>{{ report.amount }}</td>
            <td>{{ report.charge }}</td>
            <td>{{ report.total }}</td>
            <td>{{ report.balance_after }}</td>
            <td>{{ report.date }}</td>
            <td>
              {{ report.status }}
              <span
                id="dot"
                :class="getBadge(report.status)"
                class="ml-2"
              ></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons";
import "datatables.net-buttons/js/buttons.colVis";
import "datatables.net-buttons/js/buttons.flash";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import Swal from "sweetalert2";
export default {
  props: ["start_date", "stop_date", "customer_id", "category"],
  data() {
    return {
      reports: [],
    };
  },
  methods: {
    getBadge(status) {
      return status.toUpperCase() === "SUCCESSFUL" ||
        status.toUpperCase() === "REVERSED"
        ? "text-success"
        : "text-warning";
    },
    async getReports() {
      this.$store.commit("setReportsLoading", true);

      try {
        const response = await ApiService.get(
          `/partners/transactions?start_date=${this.start_date}&stop_date=${this.stop_date}&customer_id=${this.customer_id}&category=${this.category}&type=report`
        );
        console.log(response);
        this.$store.commit("setReportsLoading", false);
        if (response.data.data.length > 0) {
          this.reports = response.data.data;
          setTimeout(function() {
            // eslint-disable-next-line no-undef
            $("#example").DataTable({
              pagingType: "numbers",
              pageLength: 10,
              retrieve: true,
              responsive: true,
              processing: true,
              dom: "Bfrtip",
              buttons: ["copy", "csv", "print"],
            });
          }, 1000);
        }
      } catch (error) {
        this.$store.commit("setReportsLoading", false);
        let _error = error.response;
        Swal.fire({
          title: "Error!",
          icon: "error",
          text: _error.data
            ? _error.data.response_description
            : "Server Error, Try Again",
        });
      }
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    console.log($("#example"));
  },
};
</script>

<style scoped>
#dot {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-style: solid;
  border-width: 4px;
  border-radius: 100%;
  /* color: red; */
}
</style>
